import React, { useState } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import Scrollspy from "react-scrollspy"

import { useSiteMetadata } from "../hooks/use-site-metadata"
import { useLocation } from "@reach/router"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { navigate } from "gatsby"

const NavMenu = () => {
  const [isVisible, setVisibility] = useState(false)
  let location = useLocation();
  const { menuItems } = useSiteMetadata()


  let showStyle = null
  if (isVisible){
    showStyle = {transform: 'scaleY(1)'}
  }else{
    showStyle = null
  }

  const url = location.pathname.split('/')[1];
  const pathname = url === '' ? '/' : url;

  const handleMenuClick = (value) => {
    if (pathname !== 'privacy-policy' && pathname !== 'legal-notice') {
      scrollTo('#' + value.path)
      setVisibility(0)
    } else {
      navigate('/#' + value.path);
    }
  };

  return(
    <nav id="nav-wrap">
      <button
        id="toggle-btn"
        href="/#"
        title="Menu"
        style={{width: "20px"}}
        onClick={() => setVisibility(!isVisible)}
        >
        <FontAwesomeIcon icon={ faBars } />
      </button>

      <Scrollspy
        className="nav mobile"
        style={showStyle}
        items={ menuItems.map(a => a.path) }
        currentClassName="current"
        offset={+100} >

        {menuItems.map((value, index) => {
          return (
            <li key={index}>
              <button onClick={() => handleMenuClick(value)}>
                {value.label}
              </button>
            </li>
          )
        })}

      </Scrollspy>
    </nav>
  )
}
export default NavMenu;