import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import scrollTo from "gatsby-plugin-smoothscroll"

import content from '../../content/footer.yaml'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
  const { address, contacts } = useSiteMetadata()

  library.add(fas)


  return (
    <footer>
      <div className="row"  style={{maxWidth: '500px'}}>
        <div className="twelve columns right-cols">
          <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
            <div >
              <FontAwesomeIcon className="two" style={{margin: '0 auto', marginBottom: '10px'}} icon={ content.locationIcon } />
              <h3 className="address">{content.company}</h3>
              <p>
                { address.line1 }<br />
                { address.line2 }<br />
                { address.line3 }
              </p>
            </div>
            <div style={{margin: '0px 10px'}}></div>
            <div>
              <FontAwesomeIcon className="two" style={{margin: '0 auto', marginBottom: '10px'}} icon={ content.emailIcon } />
              <h3 className="contact">{content.contact}</h3>
              <ul>
                { contacts.map((contact, index) =>
                  <li key={index}><a href={ contact.url }>{ contact.text }</a></li>
                )}
              </ul>
            </div>
          </div>
          <br />
          <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
            <div style={{cursor: 'pointer'}}>
              <AnchorLink to="legal-notice">{content.legal}</AnchorLink>
            </div>
            <div style={{margin: '0px 10px'}}></div>
            <div style={{cursor: 'pointer'}}>
              <AnchorLink to="privacy-policy">{content.privacy}</AnchorLink>
            </div>
          </div>

          <br />
          <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
            <div className="columns">
              {content.copyright}
            </div>
          </div>

        </div>

        <div id="go-top">
          <button title="Back to Top" onClick={() => scrollTo('#top')}>
            <FontAwesomeIcon className="eight" icon={ faChevronUp } />
          </button>
        </div>
      </div>
    </footer>

  )}
export default Footer;